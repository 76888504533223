import { createTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiDataGridMenu: {
      // Name of the rule
      root: {
        // Some CSS
        zIndex: "3",
      },
    },
  },
  palette: {
    primary: {
      light: "#9ec5ac",
      main: "#3d8a58",
      dark: "#378250",
      contrastText: "#333",
    },
    secondary: {
      light: "#f9a198",
      main: "#f24231",
      dark: "#f03c2c",
      contrastText: "#333",
    },
    background: {
      default: "#fff",
    },
  },
})

export default theme
